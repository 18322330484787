import useTranslation from 'next-translate/useTranslation';
import { FC } from 'react';

import { Translate } from '@core/constant';

import { InputSubscribeControl } from './InputSubscribeControl';
import { InputEnterEmailProps } from './interface-form-input';

export const InputEnterEmail: FC<InputEnterEmailProps> = ({
  ...inputProps
}: InputEnterEmailProps): JSX.Element => {
  const { t: tAria } = useTranslation(Translate.common.ARIA);
  const { t: tForm } = useTranslation(Translate.common.FORM);

  return (
    <InputSubscribeControl
      name="email"
      aria-label={tAria('emailInput')}
      placeholder={tForm('email.placeholder')}
      type="email"
      {...inputProps}
    />
  );
};
