import { useContextGR } from '@google-recaptcha';
import { useEventEmailCaptured } from '@google-tag-manager';
import useTranslation from 'next-translate/useTranslation';
import { memo, useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import styled, { CSSObject, useTheme } from 'styled-components';

import { SubscribeService } from '@core/api';
import { Box, Button, ButtonBase, Icon, Typography } from '@core/component';
import { FormControl, InputEnterEmail } from '@core/component/form';
import { Translate } from '@core/constant';
import { useContextProfile, useContextToast, useContextUser, useContextUtil } from '@core/context';
import { useLanguage, useValidator } from '@core/hook';
import {
  EButtonVariant,
  EColor,
  EFontWeight,
  EGTMCaptureLocation,
  EIcon,
  ESize,
  SubscribeNewsletterForm,
  ThemePalette,
  ThemeProps,
  subscribeNewsletterValidator,
} from '@core/type';
import { getPaletteHandlers } from '@core/util';

const FooterSubscribeContainer = () => {
  const [hasSubscribed, setHasSubscribed] = useState<boolean>(false);
  const { t: tForm } = useTranslation(Translate.common.FORM);
  const { t: tAria } = useTranslation(Translate.common.ARIA);
  const { t: tFooter } = useTranslation(Translate.layout.FOOTER);
  const { addToast } = useContextToast();
  const { email, isLogged } = useContextUser();
  const { hasNewsletterSubscription } = useContextProfile();
  const { axiosInstance } = useContextUtil();
  const { language } = useLanguage();
  const { pushEventEmailCaptured } = useEventEmailCaptured();

  const { handleSubmit, control, trigger, reset, setError } = useForm<SubscribeNewsletterForm>({
    mode: 'onChange',
    resolver: useValidator(subscribeNewsletterValidator, tForm),
    defaultValues: {
      email: '',
    },
  });

  const { getRecaptcha } = useContextGR();

  const onSubmit = useCallback(
    async (data: SubscribeNewsletterForm) => {
      try {
        const token = await getRecaptcha();
        if (token) {
          await SubscribeService.subscribeEmail(axiosInstance, language, {
            email: data.email,
            token,
          }).then(() => {
            pushEventEmailCaptured(EGTMCaptureLocation.NEWSLETTER);
          });
          setHasSubscribed(true);
        }
      } catch {
        addToast({
          title: tFooter('subscriptionFailed'),
          description: tFooter('subscriptionFailedDescription'),
          status: EColor.WARNING,
        });
      }
    },
    [getRecaptcha, axiosInstance, addToast, tFooter],
  );

  const {
    structure: {
      footer: {
        subscribe: { height, padding, flexDirection },
      },
    },
  } = useTheme() as ThemeProps;

  return (
    <>
      {(!isLogged || !hasNewsletterSubscription) && (
        <Box
          display={'flex'}
          alignItems={'center'}
          padding={padding}
          justifyContent={'center'}
          textAlign={'center'}
          height={height}
          flexDirection={flexDirection}
          gap="24px"
        >
          {hasSubscribed ? (
            <Typography
              fontStyle="italic"
              fontSize="13px"
              color={{ commonType: EColor.WHITE, intensity: EColor.R700 }}
              fontWeight={EFontWeight.MEDIUM}
            >
              {tFooter('subscriptionSucceed')}
            </Typography>
          ) : (
            <>
              <Typography
                color={{ commonType: EColor.WHITE, intensity: EColor.R700 }}
                fontStyle="italic"
                fontSize="13px"
                fontWeight={EFontWeight.MEDIUM}
              >
                {tFooter('subscribe')}
              </Typography>
              {isLogged ? (
                <Button
                  onClick={() => {
                    void onSubmit({ email });
                  }}
                  size={ESize.SM}
                  color={EColor.WHITE}
                  variant={EButtonVariant.OUTLINED}
                  padding="0 20px"
                  lineHeight="30px"
                >
                  {tFooter('subscriptionButton')}
                </Button>
              ) : (
                <Box>
                  <FormControl
                    t={tForm}
                    trigger={trigger}
                    reset={reset}
                    handleSubmit={handleSubmit}
                    setError={setError}
                    fn={(data: SubscribeNewsletterForm) => onSubmit(data)}
                  >
                    <Box display={'flex'} position="relative">
                      <InputEnterEmail control={control} required />
                      <ButtonStyled type={'submit'} aria-label={tAria('subscriptionButton')}>
                        <Icon
                          type={EIcon.PAPER_PLANE}
                          color={{ commonType: EColor.WHITE, intensity: EColor.R900 }}
                          size={ESize.MD}
                        />
                      </ButtonStyled>
                    </Box>
                  </FormControl>
                </Box>
              )}
            </>
          )}
        </Box>
      )}
    </>
  );
};

export const FooterSubscribe = memo(FooterSubscribeContainer);

const ButtonStyled = styled(ButtonBase)(({ theme: { palette } }) => {
  const { getBackgroundColor } = getPaletteHandlers(palette as ThemePalette);

  return {
    height: '32px',
    border: '0',
    minWidth: '56px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    textAlign: 'center',
    padding: '0',
    borderRadius: '0 6px 6px 0',
    background: getBackgroundColor({ commonType: EColor.BLUE, intensity: EColor.A700 }),
  } as CSSObject;
});
