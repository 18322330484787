import { Controller } from 'react-hook-form';
import styled from 'styled-components';

import { EActionAttribute, ETypographyVariant, EColor, ThemeProps } from '@core/type';
import { getPaletteHandlers } from '@core/util';

import { Box } from '../../layout';
import { Typography } from '../../typography';
import { ControllerRenderCustomProps, InputTextControlProps } from './interface-form-input';

export const InputSubscribeControl = ({
  name,
  control,
  ...inputProps
}: Omit<InputTextControlProps, 'size'>) => (
  <Controller
    name={name}
    control={control}
    render={({ field, fieldState, defaultIsError }: ControllerRenderCustomProps) => {
      const isError = fieldState ? fieldState.isTouched && !!fieldState.error : defaultIsError;

      return (
        <>
          <StyledInput {...field} {...inputProps} name={name} />
          {fieldState && isError && (
            <Box
              margin={'0px auto'}
              paddingBottom={0}
              paddingTop={4}
              width={'100%'}
              flexDirection={'column'}
              alignItems={'flex-start'}
              display={'flex'}
              role="alert"
              position="absolute"
              top="27px"
            >
              <Typography
                as={EActionAttribute.STRONG}
                color={EColor.DANGER}
                variant={ETypographyVariant.CAPTION1}
                role="alert"
              >
                {fieldState.error.message}
              </Typography>
            </Box>
          )}
        </>
      );
    }}
  />
);

const StyledInput = styled.input<{
  theme: ThemeProps;
}>(
  ({
    theme: {
      palette,
      typography: { autocomplete },
    },
  }: {
    theme: ThemeProps;
  }) => {
    const { getTextColor, getBackgroundColor } = getPaletteHandlers(palette);

    return `
  padding: 0;
  height: 32px;
  border: none;
  box-shadow: none;
  font-size: ${autocomplete.fontSize};
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
  outline: none;
  background: ${getBackgroundColor({ commonType: EColor.BLUE, intensity: EColor.A700 })};
  opacity: 1;
  padding: 0 12px;
  width: 206px;
  max-width: 450px;
  color: ${getTextColor({ commonType: EColor.WHITE, intensity: EColor.R900 })};
  ::placeholder {
    color: ${getBackgroundColor({ semanticType: EColor.NEUTRAL, variant: EColor.LIGHT })};
  }
  :focus {
    background: ${getBackgroundColor({
      semanticType: EColor.NEUTRAL,
      variant: EColor.LIGHT,
    })};
    color: ${getTextColor(EColor.PRIMARY)};
    ::placeholder {
      color: ${getTextColor(EColor.SECONDARY)};
    }
  }
`;
  },
);
